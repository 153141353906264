import * as React from "react";
import { PageProps } from "gatsby";

const IndexPage: React.FC<PageProps> = () => (
  <div className="container">
    <main>
      <img src="/images/logo.png" alt="Octopod Delivery Service Logo" />
    </main>
    <footer>
      © {new Date().getFullYear()} Graphnode. Octopod Delivery Service, a game
      being developed by Graphnode
    </footer>

    <img className="background" src="/images/background.png" />
  </div>
);

export default IndexPage;
